import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { dashboardMenu } from '../../helpers/menu';

const LANDING = {
	DASHBOARD: lazy(() => import('../../pages/Dashboard/DashboardPage')),
	TICKETS_DASHBOARD: lazy(() => import('../../pages/Ticket/TicketsDashboard')),
	ACCOUNT_INFO: lazy(() => import('../../pages/Acconut/AccountPage')),
	VERIFY_DEVICES: lazy(() => import('../../pages/Device/Devicepage')),
	MARKETPLACE: lazy(() => import('../../pages/MarketPlace/MarketplacePage')),
	PAGENOTFOUND: lazy(() => import('../../pages/Notfound/DashNotFound')),
	REFERRAL_DASHBOARD: lazy(() =>
		import('../../pages/Referrals/ReferralDashboard'),
	),
};

const ContentRoutes = () => {
	const location = useLocation();
	return (
		<Switch location={location}>
			<Route
				exact
				path={dashboardMenu.dashboard.path}
				component={LANDING.DASHBOARD}
			/>

			<Route
				exact
				path={dashboardMenu.tickets.path}
				component={LANDING.TICKETS_DASHBOARD}
			/>
			<Route
				exact
				path={dashboardMenu.accountInfo.path}
				component={LANDING.ACCOUNT_INFO}
			/>

			<Route
				exact
				path={dashboardMenu.verifyDevice.path}
				component={LANDING.VERIFY_DEVICES}
			/>

			<Route
				exact
				path={dashboardMenu.referrals.path}
				component={LANDING.REFERRAL_DASHBOARD}
			/>

			<Route
				exact
				path={dashboardMenu.marketPlace.path}
				component={LANDING.MARKETPLACE}
			/>

			<Route path='*' component={LANDING.PAGENOTFOUND} />
		</Switch>
	);
};

export default ContentRoutes;
